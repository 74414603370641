import React , {useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Button from '../../SwitchTheme/Button'
import OpenEmailApp from '../../OpenMail';

const SideBar = ({ showSidebar, handleLinkClick, theme, setTheme }) => {

  const [title, setTitle] = useState("");
 
 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) { // Dacă ecranul are lățimea mai mică sau egală cu 576px (sm)
        setTitle("1.3rem");
     
      }  else if (window.innerWidth >= 700) { // Dacă ecranul are lățimea mai mică sau egală cu 576px (sm)
        setTitle("1rem");
      } 
  }
  
    window.addEventListener('resize', handleResize);
  
    // Apelăm funcția pentru a seta înălțimea inițială
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (
    <div
      className={`col-sm-3 col-xl-2 col-lg-3 col-md-3 ${theme === 'dark' ? 'dark-theme' : ''} ${showSidebar ? '' : 'hide-sidebar'
        }`}
      style={{ borderRight: `1px solid ${theme === 'dark' ? 'white' : 'black'}` }}
    >
      <div className=" align-items-center align-items-xl-start mt-3 "  >
        <ul className="nav  flex-column mb-0 align-items-center align-items-xl-start  align-items-md-start " id="menu">
          <li className="nav-link"
            onClick={() => handleLinkClick("portfolio")}>
            <i className="fa-solid fa-car"></i>
            <span className="mx-1" style={{fontSize:title , cursor:"pointer"}}>Mașini</span>
          </li>
          <li className="nav-link " onClick={() => handleLinkClick("addpost")}>
            <i className="fa-solid fa-plus"></i>
            <span className="mx-1" style={{fontSize:title , cursor:"pointer"}}>Adaugare Mașină </span>
          </li>
         
          <li className="nav-link"
            onClick={() => handleLinkClick("site")}>
             <i className="fa-solid fa-globe"></i>
            <span className="mx-1" style={{fontSize:title , cursor:"pointer"}}>Website MDB</span>
          </li>
         
          <Link to="/" className='nav-link'>
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
            <span className="mx-1" style={{fontSize:title , cursor:"pointer"}}>
              Iesire
            </span>
          </Link>
          <div style={{ position: "fixed", bottom: "20px" }}>
            <Button theme={theme} setTheme={setTheme} />
          </div>
        </ul>
      </div>
    </div>
  )
}

export default SideBar


