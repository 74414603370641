import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Asigură-te că ai importat 'Routes'
import SingIn from "./Components/Login/SingIn";
import Dashbord from "./Components/Dashbord/Dashbord";



const App = () => {
  return (
   
    <Router>
      <Routes>
        <Route exact path="/" element={ <SingIn/>} />
        <Route path="/dashbord" element={<Dashbord/>} />
      </Routes>
    </Router>
  );
};

export default App;
