import React from 'react'

const Pagination = ({ currentPage, handlePageChange, totalPages , theme })  => {

  return (
    <div className="d-flex justify-content-center ">
     <ul className={` ${theme === "dark" ? "dark-theme-pagination" : "pagination"}`}>
      
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}><i className="fa-solid fa-chevron-left"></i></button>
      </li>
      {Array.from({ length: totalPages }, (_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        </li>
      ))}
      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}><i className="fa-solid fa-chevron-right"></i></button>
      </li>
    </ul>
  </div>
  )
}

export default Pagination
