import React, { useState } from 'react'
import { marciDeMasini } from "../Add post/listcars";
import { listaAnFabricatie } from "../Add post/anFabricatie";
import { comfortOptions, audioOptions, sigurantaOptions, asistentaOptions ,pretCuTVAOption } from "../Add post/optiuni";
export const EditModal = ({
  marca,
  setMarca,
  model,
  setModel,
  combustibil,
  setCombustibil,
  putere,
  setPutere,
  cutieViteza,
  setCutieViteza,
  normaPoluare,
  setNormaPoluare,
  culoare,
  setCuloare,
  anFabricatie,
  setAnFabricatie,
  rulaj,
  setRulaj,
  selectedImages,
  setSelectedImages,
  selectedMainImg,
  setSelectedMainImg,
  motor,
  setMotor,
  caroserie,
  setCaroserie,
  tractiune,
  setTractiune,
  pret,
  setPret,
  setSelectedComfortOptions,
  selectedComfortOptions,
  setSelectedAsistentaOptions,
  selectedAsistentaOptions,
  setSelectedSigurantaOptions,
  selectedSigurantaOptions,
  setSelectedAudioOptions,
  selectedAudioOptions,
  handleMainImageChange,
  main,
  handleImagesChange,
  images,
  handleUpdate,
  theme,
  deleteSelectedImage,
  handleImageChange,
  sasiu,
  setSasiu,
  pretCuTVA,
  setPretCuTVA,
  showModal , setShowModal,
  discount,
  setDiscount,
  descriere,
setDescriere,
garantie,
setGarantie,
tara,
setTara,
inmatriculare,
setInmatriculare,
inmatriculat,
setInmatriculat,
propietar,
setPropietar,
service,
setService,
accident,
setAccident,
locuri,
setLocuri,
comanda,
setComanda,
titlu, 
setTitlu
}) => {


  const handleDescriere = (e) => {
    let inputValue = e.target.value;
    let newValue = '';
  
    for (let i = 0; i < inputValue.length; i++) {
      if (i === 0 || (inputValue[i - 1] === '.' || inputValue[i - 1] === ';' || inputValue[i - 1] === '\n')) {
        // Capitalizează primul caracter din fiecare nou rând sau după un punct sau semn de punctuație
        newValue += inputValue[i].toUpperCase();
      } else {
        newValue += inputValue[i];
      }
    }
  
    setDescriere(newValue);

  };

  const handleComfortOptionChange = (comfort) => {
    setSelectedComfortOptions((prevOptions) => ({
      ...prevOptions,
      [comfort]: !prevOptions[comfort],
    }));
  };

  const handleAudioOptionChange = (audio) => {
    setSelectedAudioOptions((prevOptions) => ({
      ...prevOptions,
      [audio]: !prevOptions[audio],
    }));
  };

  const handleSigurantaOptionChange = (siguranta) => {
    setSelectedSigurantaOptions((prevOptions) => ({
      ...prevOptions,
      [siguranta]: !prevOptions[siguranta],
    }));
  };

  const handleAsistentaOptionChange = (asistenta) => {
    setSelectedAsistentaOptions((prevOptions) => ({
      ...prevOptions,
      [asistenta]: !prevOptions[asistenta],
    }));
  };


  return (
    <>
    

    <div className="modal fade modal-xl  " id="editModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  >
      <div className="modal-dialog modal-fullscreen  "  >
        <div className={`modal-content  ${theme === "dark" ? "dark-theme" : ""}`}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editare autoturism</h5>
          </div>
          <div className="modal-body" >
          { showModal && (
      <div className='modalLoading'>
        <div className="spinner-border  text-light" >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
        )}
            <div className="row g-3">
            <div className="row g-3">
            <div className="col-sm-12">
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={comanda}
                      id="comanda"
                      onChange={(e) => setComanda(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="comanda">
                  Pe comandă
                  </label>
                  </div>
                </div>
                 <div className="col-sm-12">
                <label htmlFor="titlu" className="form-label">Scurta descriere</label>
                <input
                  type="text"
                  value={titlu}
                  id="titlu"
                  
                  className="form-control"
                  onChange={(e) => setTitlu(e.target.value.toUpperCase())}
                />
              </div>
                </div>
              <div className="col-sm-2">
                <label htmlFor="marca" className="form-label">
                  Marcă
                </label>
                <select
                  value={marca}
                  className="form-select"
                  id="marca"
                  onChange={(e) => setMarca(e.target.value)}
                >
                  <option value="">Alege...</option>
                  {marciDeMasini.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="model" className="form-label">Model</label>
                <input
                  type="text"
                  value={model}
                  id="model"
                  className="form-control"
                  onChange={(e) => setModel(e.target.value.toUpperCase())}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="combustibil" className="form-label">
                  Combustibil
                </label>
                <select
                  value={combustibil}
                  className="form-select"
                  id="combustibil"
                  onChange={(e) => setCombustibil(e.target.value)}
                >
                  <option value="">Alege...</option>
                  <option value="Benzină">Benzină</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Hibrid">Hibrid</option>
                  <option value="Electric">Electric</option>
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="motor" className="form-label">
                  Motor cm3
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="motor"
                  value={motor}
                  onChange={(e) => setMotor(e.target.value)}

                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="tractiune" className="form-label">
                  Tracțiune
                </label>
                <select
                  value={tractiune}
                  onChange={(e) => setTractiune(e.target.value)}
                  className="form-select"
                  id="tractiune"

                >
                  <option value="">Alege...</option>
                  <option value="Fata">Față</option>
                  <option value="Spate">Spate</option>
                  <option value="4X4 Automat">4X4 Automat</option>
                  <option value="4X4 Manual">4X4 Manual</option>

                </select>
              </div>

              <div className="col-sm-2">
                <label htmlFor="putere" className="form-label">
                  Putere KW
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="putere"
                  value={putere}
                  onChange={(e) => setPutere(e.target.value)}

                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="cutieViteza" className="form-label">
                  Cutie Viteză
                </label>
                <select
                  value={cutieViteza}
                  className="form-select"
                  id="cutieViteza"
                  onChange={(e) => setCutieViteza(e.target.value)}

                >
                  <option value="">Alege...</option>
                  <option value="Manuală">Manuală</option>
                  <option value="Automată">Automată</option>
                  <option value="Semiautomată">Automată</option>
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="normaPoluare" className="form-label">
                  Normă Poulare
                </label>
                <select
                  value={normaPoluare}
                  onChange={(e) => setNormaPoluare(e.target.value)}
                  className="form-select"
                  id="normaPoluare"

                >
                  <option value="">Alege...</option>
                  <option value="Euro 3">Euro 3</option>
                  <option value="Euro 4">Euro 4</option>
                  <option value="Euro 5">Euro 5</option>
                  <option value="Euro 6">Euro 6</option>

                </select>
              </div>

              <div className="col-sm-2">
                <label htmlFor="culoare" className="form-label">
                  Culoare
                </label>
                <select
                  value={culoare}
                  onChange={(e) => setCuloare(e.target.value)}
                  className="form-select"
                  id="culoare"

                >
                  <option value="">Alege...</option>
                  <option value="Alb">Alb</option>
                  <option value="Negru">Negru</option>
                  <option value="Gri">Gri</option>
                  <option value="Argintiu">Argintiu</option>
                  <option value="Albastru">Albastru</option>
                  <option value="Rosu">Rosu</option>
                  <option value="Verde">Verde</option>
                  <option value="Galben/Auriu">Galben/Auriu</option>
                  <option value="Maro/Bej">Maro/Bej</option>
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="anfab" className="form-label">
                  An Fabricație
                </label>
                <select
                  value={anFabricatie}
                  className="form-select"
                  id="anfab"
                  onChange={(e) => {
                    // Parsează valoarea selectată ca număr
                    const selectedValue = parseInt(e.target.value, 10);
                    // Actualizează starea anFabricatie cu valoarea numerică
                    setAnFabricatie(selectedValue);
                  }}

                >
                  <option value="">Alege...</option>
                  {listaAnFabricatie.map((an, index) => (
                    <option value={an} key={index}>{an}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="rulaj" className="form-label">
                  Rulaj KM
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="rulaj"
                  value={rulaj}
                  onChange={(e) => setRulaj(e.target.value)}

                ></input>
              </div>
              <div className="col-sm-2">
                <label htmlFor="caroserie" className="form-label">
                  Caroserie
                </label>
                <select
                  value={caroserie}
                  onChange={(e) => setCaroserie(e.target.value)}
                  className="form-select"
                  id="caroserie"

                >
                  <option value="">Alege...</option>
                  <option value="Cabrio">Cabrio</option>
                  <option value="Berlină">Berlină</option>
                  <option value="Break">Break</option>
                  <option value="Hatchback">Hatchback</option>
                  <option value="SUV">SUV</option>
                  <option value="Minibus">Minibus</option>
                  <option value="Monovolum">Monovolum</option>
                  <option value="Coupe">Coupe</option>
                  <option value="Pickup">Pickup</option>
                </select>
              </div>

              <div className="col-sm-2">
                <label htmlFor="pret" className="form-label">Preț</label>
                <input
                  type="number"
                  value={pret}
                  id="pret"
                  className="form-control"
                  onChange={(e) => setPret(e.target.value)}
                />
              </div>
              <div className="col-sm-4">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={pretCuTVA}
                      id="tva"
                      onChange={(e) => setPretCuTVA(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="tva">
                    Preț cu TVA <span style={{color:"gray", fontSize:"0.9rem" , marginLeft:"7px"}}> - dacă este bifat mașina are TVA -</span>
                  </label>
                  </div>
                </div>

              <div className="col-sm-3">
                <label htmlFor="sasiu" className="form-label">Serie Șasiu</label>
                <input
                  type="text"
                  value={sasiu}
                  id="sasiu"
                  className="form-control"
                  onChange={(e) => setSasiu(e.target.value.toUpperCase())}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="discount" className="form-label">Preț Nou</label>
                <input
                  type="number"
                  value={discount}
                  id="discount"
                  className="form-control"
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>

              <div className="col-sm-12">
                  <label htmlFor="descriere" >
                  Descriere
                  </label>
                  <textarea
                    type="descriere"
                    className="form-control"
                    id="descriere"
                    value={descriere}
                    rows={3}
                    onChange={handleDescriere}
                    style={{ resize: "none" }}
                 
                  />
                </div>
                <div className="col-sm-1">
                <label htmlFor="garantie" className="form-label">Garanție</label>
                <input
                  type="number"
                  value={garantie}
                  id="garantie"
                  className="form-control"
                  onChange={(e) => setGarantie(e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="tara" className="form-label"> Tara de Origine</label>
                <input
                  type="text"
                  value={tara}
                  id="tara"
                  className="form-control"
                  onChange={(e) => setTara(e.target.value.toUpperCase())}
                />
              </div>
              <div className="col-sm-1">
                <label htmlFor="locuri" className="form-label"> Numar locuri</label>
                <input
                  type="number"
                  value={locuri}
                  id="locuri"
                  className="form-control"
                  onChange={(e) => setLocuri(e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="inmatriculare" className="form-label">  Data primei inmatriculari</label>
                <input
                  type="text"
                  value={inmatriculare}
                  id="inmatriculare"
                  className="form-control"
                  onChange={(e) => setInmatriculare(e.target.value)}
                />
              </div>
              <hr className="my-4" />
              <div className="row gy-1">
             
              <div className="col-sm-2">
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={inmatriculat}
                      id="inmatriculat"
                      onChange={(e) => setInmatriculat(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="inmatriculat">
                  Inmatriculat 
                  </label>
                  </div>
                </div>
              
                <div className="col-sm-2">
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={service}
                      id="service"
                      onChange={(e) => setService(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="service">
                  Carte service
                  </label>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={propietar}
                      id="propietar"
                      onChange={(e) => setPropietar(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="propietar">
                  Primul propietar
                  </label>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={accident}
                      id="accident"
                      onChange={(e) => setAccident(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="accident">
                  Fara accident in istoric
                  </label>
                  </div>
                </div>



                <hr className="my-4" />
                </div>



              {/* dotari */}
              <div className="row gy-3">
                <div className="col-sm-12 h5"  type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                Comfort si Optionale <i className="fa-solid fa-caret-down"  ></i>
                </div>
                <div className="col-sm-12 collapse" id="collapseExample"> 
                  {comfortOptions.map((comfort) => (
                    <div key={comfort} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={comfort}
                        checked={selectedComfortOptions[comfort] || false}
                        onChange={() => handleComfortOptionChange(comfort)}
                      />
                      <label className="form-check-label" htmlFor={comfort}>
                        {comfort}
                      </label>
                    </div>
                  ))}
                </div>

                <hr className="my-4" />
              </div>
              {/* Audio */}

              <div className="row gy-3">
                   <div className="col-sm-12 h5"  type="button" data-toggle="collapse" data-target="#audioCollapse" aria-expanded="false" aria-controls="audioCollapse" >
                   Audio si Tehnologie  <i className="fa-solid fa-caret-down" ></i>
</div>
<div className="col-sm-12 collapse" id="audioCollapse"> 
                  {audioOptions.map((audio) => (
                    <div key={audio} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={audio}
                        checked={selectedAudioOptions[audio] || false}
                        onChange={() => handleAudioOptionChange(audio)}
                      />
                      <label className="form-check-label" htmlFor={audio}>
                        {audio}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>
              {/* Siguranta */}

              <div className="row gy-3">
                 <div className="col-sm-12 h5" type="button" data-toggle="collapse" data-target="#sigurantaCollapse" aria-expanded="false" aria-controls="sigurantaCollapse">

Siguranta si Performanta <i className="fa-solid fa-caret-down"  ></i>
</div>
  <div className="col-sm-12 collapse" id="sigurantaCollapse"> 
                  {sigurantaOptions.map((siguranta) => (
                    <div key={siguranta} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={siguranta}
                        checked={selectedSigurantaOptions[siguranta] || false}
                        onChange={() => handleSigurantaOptionChange(siguranta)}
                      />
                      <label className="form-check-label" htmlFor={siguranta}>
                        {siguranta}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>
              {/* Asistenta */}
              <div className="row gy-3">
              <div className="col-sm-12 h5"  type="button" data-toggle="collapse" data-target="#asistentaCollapse" aria-expanded="false" aria-controls="asistentaCollapse">

Asistenta si Electronice <i className="fa-solid fa-caret-down" ></i>
</div>
  <div className="col-sm-12 collapse" id="asistentaCollapse"> 
                  {asistentaOptions.map((asistenta) => (
                    <div key={asistenta} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={asistenta}
                        checked={selectedAsistentaOptions[asistenta] || false}
                        onChange={() => handleAsistentaOptionChange(asistenta)}
                      />
                      <label className="form-check-label" htmlFor={asistenta}>
                        {asistenta}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>

              {/* Imagini */}


              <div className="col-sm-4 col-xl-4 col-md-4">
                <p>Imagine Principala</p>
                <div className="text-center">
                  <img src={main && Object.values(main)[0]} loading='lazy' alt={marca} style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    marginRight: "5px",
                    display: "inline-block"

                  }}
                  />
                </div>
              </div>
              <div className="col-sm-8 col-xl-8 col-md-8 text-right  text-md-center">
                <p>Restul imaginilor</p>
                <div className="row mb-4">
                  {images &&
                    Object.keys(images).map((key) => (
                      <div className='col-sm-2 text-center mx-1 mb-2' key={key}>
                        <img
                          src={images[key]}
                          alt={marca}
                          loading='lazy'
                          className='text-center mb-2 '
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                            marginRight: "5px",
                          }}
                        />
                        <i className="fa-solid fa-trash text-center" style={{ cursor: "pointer" }} onClick={() => deleteSelectedImage(key)}></i>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-4">
                <label htmlFor="mainImage" className="form-label">
                  Schimbă imagine principală
                </label>
                <input
                  type="file"
                  className="form-control mb-2 input-text"
                  id="mainImage"
                  onChange={handleMainImageChange}
                />
              </div>



              <div className="col-sm-4 col-xl-4 col-md-6">
                <label htmlFor="images" className="form-label">
                  Schimbă toate imaginile - maxim 25
                </label>
                <input
                  type="file"
                  className="form-control mb-2"
                  id="images"
                  multiple
                  onChange={handleImagesChange}
                />
              </div>

            </div>
            <div className="modal-footer mt-3">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Închide
              </button>
              <button className="btn btn-primary" onClick={handleUpdate}>
                Actualizare
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
