export const marciDeMasini = [
    "ALFA ROMEO",
    "AUDI",
    "AUSTIN",
    "BMW",
    "BYD",
    "BUICK",
    "CADILLAC",
    "CHRYSLER",
    "CITROEN",
    "CUPRA",
    "DACIA",
    "DODGE",
    "FIAT",
    "FORD",
    "GMC",
    "HONDA",
    "HYOSUNG",
    "HYUNDAI",
    "IVECO",
    "ISUZU",
    "JAGUAR",
    "JEEP",
    "KIA",
    "KTM",
    "LAND ROVER",
    "LEXUS",
    "LIFAN",
    "LIGIER",
    "LYNK & CO",
    "MAZDA",
    "MERCEDES-BENZ",
    "MG",
    "MINI",
    "MITSUBISHI",
    "NISSAN",
    "OPEL",
    "OLDSMOBILE",
    "PEUGEOT",
    "PLYMOUTH",
    "POLARIS",
    "PONTIAC",
    "PORSCHE",
    "RAM",
    "RENAULT",
    "ROVER",
    "SAAB",
    "SEAT",
    "SKODA",
    "SMART",
    "SUBARU",
    "SUZUKI",
    "TESLA",
    "TOYOTA",
    "TRIUMPH",
    "VOLKSWAGEN",
    "VOLVO",
    "ZHONGYU"
];
