import React from 'react';

class OpenEmailApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title || "1rem", // Utilizează valoarea din prop sau "1rem" ca valoare implicită
    };
  }
   
    handleOpenEmailApp = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Verifică dacă utilizatorul folosește un dispozitiv mobil și un browser compatibil cu Gmail
        if (/android/i.test(userAgent)) {
          window.location.href = 'mailto://';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.location.href = 'message://';
        } else {
          // Dacă nu este pe un dispozitiv mobil sau nu se utilizează un browser compatibil cu Gmail, deschide aplicația de e-mail implicită
          window.location.href = 'mailto://';
        }
      };
  render() {


    return (
      <div >
        <i className="fa-regular fa-envelope"></i>
        <span className="mx-1"   style={{ fontSize: this.state.title, cursor: "pointer" }} onClick={this.handleOpenEmailApp}>E-mail</span>
       
      </div>
    );
  }
}

export default OpenEmailApp;
