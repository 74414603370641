import React, { useState, useEffect } from 'react';
import {auth, firestore } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import SideBar from "./Sidebar/SideBar";
import RenderDataComponent from "../Projects Render/render";
import AddProject from "../Add post/AddProject";
import "./dash.css";
import MyChartComponent from '../Chart';
import Iframe from '../Iframe';



const Dashbord = () => {
  const [selectedComponent, setSelectedComponent] = useState("portfolio");
  const [user, setUser] = useState(null);

    const [showSidebar, setShowSidebar] = useState(true); // Inițial, starea depinde de lățimea ecranului

    const [addClass, setaddClass] = useState("col-md-9 col-xl-10 px-md-0")
  
   
  const [theme, setTheme] = useState("dark");
  
  const handleLinkClick = (componentName) => {
    setSelectedComponent(componentName);
    if (window.innerWidth <= 500) {
      setShowSidebar(!showSidebar); // Inversează starea doar pe ecrane mici
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  
    // Definește clasele CSS în funcție de starea sidebarului
    const containerClasses = showSidebar ? 'container-fluid' : 'col-md-9 col-xl-10 px-md-0' ;;
    setaddClass(containerClasses);
  };
  

  useEffect(() => {
    const body = document.querySelector("body");
    body.classList.remove("light-theme", "dark-theme");
    body.classList.add(`${theme}-theme`);
  }, [theme]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);
 

  // Total Proiecte
  const [totalDocuments, setTotalDocuments] = useState(null);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'post'));
        const dataArray = [];

        querySnapshot.forEach((doc) => {
          // Incrementează totalDocuments pentru fiecare document găsit
          setTotalDocuments(querySnapshot.size);

        });
      } catch (error) {
        console.error('Eroare la obținerea datelor:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`container-fluid ${theme === "dark" ? "dark-theme" : " "}`}>
      <div className="  row align-items-center" style={{ borderBottom: `1px solid ${theme === "dark" ? "white" : "black" }`}}>
       <div className='col-sm-2 col-md-3 col-xl-2'>
         <h3 className='my-2 p-2 '>MDB Auto</h3> 
         </div>
         <div className='col-sm-2'>
        <button
          onClick={toggleSidebar}
          className="btn btn-dark  px-2 py-0 "
          >
          <i className={`fa ${showSidebar ? "fa-bars" : "fa-bars"}`}></i>
        </button>
          </div>
          <div className='col-sm-4'>
        <h3 className="my-2 p-2 text-center ">Adminstrare Mașini</h3>
       </div>
      </div>
      <div className="row flex-nowrap">
        <SideBar
          showSidebar={showSidebar}
          handleLinkClick={handleLinkClick}
          theme={theme} // Pasează tema către Sidebar
          setTheme={setTheme} 
         
        />
        <div className={addClass}>
        {selectedComponent === "portfolio" && (
    <div className='vh-100' >
        <div className="total-projects">
            <p className='mx-3 mt-3 fs-5'>Total {totalDocuments} autoturisme pe <a className='link-underline-dark' href='https://parcautotimisoara.ro/masini'> Website </a></p>
            <MyChartComponent theme={theme} />
        </div>
        <RenderDataComponent handleLinkClick={handleLinkClick} theme={theme} />
    </div>
)}

          {selectedComponent === "addpost" && (
            <AddProject handleLinkClick={handleLinkClick} theme={theme}/>
          )}

{selectedComponent === "site" && (
            <Iframe handleLinkClick={handleLinkClick} theme={theme}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
