import React from 'react'

const Iframe = () => {

    const webmailURL = 'https://parcautotimisoara.ro';
  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }} >
         <iframe
        title="Webmail"
        src={webmailURL}
        // Folosește 100vh pentru a umple înălțimea ecranului
        style={{ border: 'none' , width:"100%", height:"100%" }}
      ></iframe>

    </div>
  )
}

export default Iframe;