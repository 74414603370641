import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { marciDeMasini } from "../Components/Add post/listcars";

function MyChartComponent({ theme }) {
  const [rezultateInterogare, setRezultateInterogare] = useState({});

  useEffect(() => {
    // Configurație Firebase
    const firebaseConfig = {
      apiKey: "AIzaSyD6sFxWclayaP0eUblAOSu8K1uUo17YK74",
      authDomain: "parcautotimisoara-v1.firebaseapp.com",
      projectId: "parcautotimisoara-v1",
      storageBucket: "parcautotimisoara-v1.appspot.com",
      messagingSenderId: "961049894063",
      appId: "1:961049894063:web:13bd72c7738e9156c1085a"
    };
    const firebaseApp = initializeApp(firebaseConfig);

    // Obține o referință la colecția din Firestore
    const db = getFirestore(firebaseApp);

    const functiiInterogareMarca = {};

    marciDeMasini.forEach((marca) => {
      functiiInterogareMarca[marca] = (firestore) => {
        return query(collection(firestore, 'post'), where("marca", "==", marca));
      };
    });

    const fetchData = async () => {
      try {
        const postCollectionRef = collection(db, 'post'); // Folosește db în loc de firestore
        const querySnapshot = await getDocs(postCollectionRef);

        // Interogăm baza de date pentru fiecare marcă și stocăm rezultatele
        const rezultateInterogare = {};
        for (const marca of marciDeMasini) {
          const interogare = functiiInterogareMarca[marca];
          if (interogare) {
            const colectie = query(postCollectionRef, interogare(db)); // Folosește db în loc de firestore
            const snapshot = await getDocs(colectie);

            // Verificăm dacă documentele conțin marca corespunzătoare și numărăm doar acele documente
            const count = snapshot.docs.filter((doc) => doc.data().marca === marca).length;
            rezultateInterogare[marca] = count;
          }
        }

        setRezultateInterogare(rezultateInterogare);
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    };

    fetchData();
  }, []);

  const textFillColor = theme === 'dark' ? '#ffffff' : '#000000';

  return (
    <div>
      <Chart
        chartType="PieChart"
        data={[
          ['Marca', 'Count'],
          ...marciDeMasini.map((marca) => [marca, rezultateInterogare[marca]])
        ]}
        options={{
          is3D: true,
          backgroundColor: theme === 'dark' ? '#212529' : '#f7f7f7',
          legend: {
            textStyle: {
              color: textFillColor
            }
          },
          chartArea: {
            textStyle: {
              color: textFillColor
            }
          }
        }}
        graph_id="piechart_3d"
        width={'100%'}
        height={'400px'}
      />
    </div>
  );
}

export default MyChartComponent;
