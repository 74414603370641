export const comfortOptions = [
    "Aer condiționat",
    "Climatronic",
    "Climatronic 2 zone",
    "Climatronic 4 zone",
    "Climatizare spate",
    "Tapiterie alcantara",
    "Tapiterie mixta piele-textil",
    "Tapiterie piele",
    "Tapiterie stofa",
    "Scaun șofer ajustabil electric",
    "Scaun pasager ajustabil electric",
    "Încălzire scaun șofer",
    "Încălzire scaun pasager",
    "Suport lombar electric scaun șofer",
    "Suport lombar electric scaun pasager",
    "Scaune față ventilate",
    "Scaune față cu masaj",
    "Scaun cu memorie",
    "Scaune sport față",
    "Încălzire scaune spate",
    "Scaune spate ventilate",
    "Scaune spate cu masaj",
    "Cotieră (față)",
    "Cotieră (spate)",
    "Volan piele",
    "Volan sport",
    "Volan cu comenzi",
    "Volan reglabil electric",
    "Volan multifuncțional",
    "Încălzire volan",
    "Volan cu schimbător de viteze",
    "Schimbător viteze piele",
    "Cheie digitală",
    "Keyless entry",
    "Keyless go",
    "Pornire motor Keyless",
    "Încălzire auxiliară",
    "Încălzire cu reglare automată",
    "Senzor ploaie",
    "Parbriz cu încălzire",
    "Ștergătoare parbriz",
    "Geamuri electrice față",
    "Geamuri electrice spate",
    "Privacy glass",
    "Top electric",
    "Top cu telecomandă",
    "Carlig remorcare",
    "Plafon panoramic",
    "Trapa electrica",
    "Trapa manuala",
    "Trapa sticla spate electrica",
    "Jaluzele electrice geamuri spate",
    "Jaluzele manuale geamuri spate",
    "Hardtop",
    "Top material plastic",
    "Top Stofa"
  ];
  

  export const audioOptions = [
    "Apple Carplay",
    "Android Auto",
    "Bluetooth",
    "Radio",
    "Sistem hands-free",
    "Port USB",
    "Wireless charging",
    "Sistem navigatie",
    "Sistem audio",
    "Head up display",
    "Monitor cu touch screen",
    "Control vocal",
    "Conexiune Internet",
    "Navigatie"
  ];

  export const sigurantaOptions = [
    "ABS",
    "ESP",
    "EBD",
    "Franare asistata",
    "Sistem asistenta franare oras",
    "Sistem franare automata pietoni",
"Sistem avertizare marsarier",
"Avertizare sonora pre-coliziune",
"Sistem pre-coliziune lateral",
"Active lane control assistant",
"Sistem activ franare urgenta",
"Roll bar",
"Sistem monitorizare stare sofer",
    "Airbag",
    "Isofix",
    "Jante otel",
    "Jante aliaj 14",
    "Jante aliaj 15",
    "Jante aliaj 16",
    "Jante aliaj 17",
    "Jante aliaj 18",
    "Jante aliaj 19",
    "Jante aliaj 20",
    "Jante aliaj 21",
    "Jante aliaj 22",
    "Jante aliaj 23",
    "Anvelope iarna",
    "Anvelope vara",
    "Anvelope all season",
    "Anvelope OFF-ROAD",
    "Anvelope Run-flat",
    "Suspensie controlata electronic",
    "Suspensie sport",
    "Suspensie confort",
    "Suspensie pneumatica",
    "Suspensie reglabila",
    "Filtru particule",


  ];
  
export  const asistentaOptions = [
    "Senzori parcare fata",
    "Senzori parcare spate",
    "Pilot automat",

    "Pilot automat adaptiv (distronic)",
    "Adaptive Cruise Control Predictive",
    "Asistenta la parcare",
    "Sistem de parcare automat",
    "Camera video 360º",
    "Camera video spate",
    "Lane assist",
    "Controlul distantei",
    "Limitator viteza",
    "Asistenta la franare",
    "Faruri LED",
    "Faruri Bi-Xenon",
    "Faruri Laser",
    "Faruri Xenon",
    "Lumini de zi",
    "Lumini de zi LED",
    "Faruri ceata",
    "Faruri ceata LED",
    "Stopuri LED",
    "Sistem Start/Stop",
    "Frana parcare electrica"
  ];


  export const pretCuTVAOption = [
    true,
    false
  ]
  
  