import React from 'react'


const Table = ({currentRows,handleEdit,handleDelete ,theme , showModal , setShowModal}) => {

  
  const sortByDate = (data) => {
    return data.sort((a, b) => {
      // Asumăm că 'createdAt' este de tip timestamp în format milisecunde
  
      // Convertim 'createdAt' la număr pentru a putea sorta
      const timestampA = a.createdAt;
      const timestampB = b.createdAt;
  
      // Sortare în ordine descrescătoare (cele mai noi mai întâi)
      return timestampB - timestampA;
    });
  };
  // Sortați datele înainte de a le afișa în tabel
  const sortedCurrentRows = sortByDate(currentRows);

  
  return (
    <>  
     { showModal && (
      <div className='modalLoading'>
        <div className="spinner-border  text-light" >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
        )}
        <div className='table-responsive small'>
    <table className={`table table-sm  ${theme === "dark" ? "table-dark" : ""}`}>
      <thead>
        <tr className='text-center'>
          <th scope="col" className='col-1'>IMG</th>
          <th scope="col" className='col-1'>Marca</th>
          <th scope="col" className='col-1 d-none d-md-table-cell'>Model</th>
          <th scope="col" className='col-1 d-none d-md-table-cell'>Combustibil</th>
          <th scope="col" className='col-1 d-none d-md-table-cell'>Cm&#179;</th>
          <th scope="col" className='col-1 d-none d-md-table-cell'>Km</th>
          <th scope="col" className=' col-1 d-none d-md-table-cell'>Cutie</th>
          <th scope="col" className=' col-1 d-none d-md-table-cell'>CP</th>
          <th scope="col" className=' col-1 d-none d-md-table-cell'>Preț</th>
          <th scope="col" className='col-1'>Editare</th>
          <th scope="col" className='col-1'>Stergere</th>
        </tr>
      </thead>
      <tbody>
      {sortedCurrentRows.map((values, index) => (
          <tr key={index} className="align-middle text-center" >
            <td> {values.mainImage && Object.keys(values.mainImage).map((key) => (
              <img
                key={key}
                src={values.mainImage[key]}
                alt={values.title}
                style={{ width: "40px", height: "40px", objectFit: "cover" }}
              />
            ))}</td>
            <td className="align-middle ">{values.marca}</td>
            <td className="align-middle d-none d-md-table-cell">{values.model}</td>
            <td className="align-middle d-none d-md-table-cell">{values.combustibil}</td>
            <td className="align-middle d-none d-md-table-cell">{values.motor}</td>
            <td className="align-middle d-none d-md-table-cell">{values.rulaj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
            <td className="align-middle d-none d-md-table-cell">{values.cutieViteza}</td>
            <td className="align-middle d-none d-md-table-cell">{values.putere}</td>
            <td className="align-middle d-none d-md-table-cell">{values.pret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
            <td>
              <button className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                onClick={() => handleEdit(values)}>
                <i className="fa-regular fa-pen-to-square"></i>
              </button>
            </td>
            <td>

              
              <button className="btn btn-primary btn-sm"
                onClick={() => handleDelete(values.id)}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  </>

  )
}

export default Table
