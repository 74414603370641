import React, { useState, useEffect, useRef } from 'react';
import { app , auth, firestore ,storage } from '../../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc ,getDoc} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getStorage, deleteObject } from 'firebase/storage';
import { comfortOptions ,audioOptions ,sigurantaOptions,asistentaOptions  } from "../Add post/optiuni";
import { Modal } from 'bootstrap';
import "./render.css"
import { EditModal } from '../Modal/EditModal';
import Pagination from '../Pagination/Pagination';
import Table from './Table';

async function compressImage(file, quality) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculăm noile dimensiuni ale imaginii pentru compresie
        const maxWidth = 800;
        const maxHeight = 600;
        let newWidth, newHeight;

        if (image.width > image.height) {
          newWidth = maxWidth;
          newHeight = (image.height / image.width) * maxWidth;
        } else {
          newHeight = maxHeight;
          newWidth = (image.width / image.height) * maxHeight;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        // Desenăm imaginea pe canvas cu noile dimensiuni
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        // Obținem imaginea comprimată sub formă de Blob
        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name, { type: 'image/jpeg' }));
        }, 'image/jpeg', quality);
      };
    };

    reader.readAsDataURL(file);
  });
}


const RenderDataComponent = ({theme}) => {

  const [marca, setMarca] = useState("");
  const [model, setModel] = useState("");
  const [combustibil, setCombustibil] = useState("");
  const [putere, setPutere] = useState("");
  const [cutieViteza, setCutieViteza] = useState("");
  const [normaPoluare, setNormaPoluare] = useState("");
  const [culoare, setCuloare] = useState("");
  const [anFabricatie, setAnFabricatie] = useState("");
  const [rulaj, setRulaj] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedMainImg, setSelectedMainImg] = useState([]);
  const [motor, setMotor] = useState("");
  const [caroserie, setCaroserie] = useState("");
  const [tractiune, setTractiune] = useState("");
  const [pret, setPret] = useState("");
  const [pretCuTVA, setPretCuTVA] = useState("");
  const [discount, setDiscount] = useState("");
  const [descriere, setDescriere] = useState("");
  const [sasiu, setSasiu] = useState("")
  const [garantie, setGarantie] = useState("");
  const [tara, setTara] = useState("");
  const [inmatriculare, setInmatriculare] = useState("");
  const [inmatriculat, setInmatriculat] = useState(false);
  const [propietar, setPropietar] = useState(false);
  const [service, setService] = useState(false);
  const [accident, setAccident] = useState(false);
  const [locuri, setLocuri] = useState("");
 
  const [comanda, setComanda] = useState(false);
  const [titlu, setTitlu] = useState("");

  const [main, setMain] = useState([])
  const [images, setImages] = useState([])

  const [selectedComfortOptions, setSelectedComfortOptions] = useState({});
  const [selectedAudioOptions, setSelectedAudioOptions] = useState({});
  const [selectedSigurantaOptions, setSelectedSigurantaOptions] = useState({});
  const [selectedAsistentaOptions, setSelectedAsistentaOptions] = useState({});




  const getSelectedComfortOptions = (firestoreComfortOptions) => {
    const selectedOptions = {};
    comfortOptions.forEach((option) => {
      selectedOptions[option] = firestoreComfortOptions[option] === true;
    });
    return selectedOptions;
  };

  const getSelectedAudioOptions = (firestoreAudioOptions) => {
    const selectedOptions = {};
    audioOptions.forEach((option) => {
      selectedOptions[option] = firestoreAudioOptions[option] === true;
    });
    return selectedOptions;
  };

  const getSelectedSigurantaOptions = (firestoreSigurantaOptions) => {
    const selectedOptions = {};
    sigurantaOptions.forEach((option) => {
      selectedOptions[option] = firestoreSigurantaOptions[option] === true;
    });
    return selectedOptions;
  };

  const getSelectedAsistentaOptions = (firestoreAsistentaOptions) => {
    const selectedOptions = {};
    asistentaOptions.forEach((option) => {
      selectedOptions[option] = firestoreAsistentaOptions[option] === true;
    });
    return selectedOptions;
  };
  

  const [currentDocId, setCurrentDocId] = useState(null); // Acesta va păstra ID-ul documentului curent pentru editare
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [value, setValue] = useState([])

  // spining modal 
  const [showModal  ,setShowModal] = useState(false)  



  
  const handleMainImageChange = async (e) => {
    const image = e.target.files[0];
    
    if (image) {
      try {
        const compressedImage = await compressImage(image, 0.8); // Comprimăm imaginea
        setSelectedMainImg([compressedImage]); // Setăm imaginea comprimată în starea componentei
      } catch (error) {
        console.error("Eroare la comprimarea imaginii:", error);
      }
    }
  };
  // Funcție pentru gestionarea schimbărilor la imagini suplimentare
  const handleImagesChange = async (e) => {
    const images = e.target.files;
    const imagesArray = Array.from(images);
  
    // Limităm numărul de imagini la 25 (dacă utilizatorul selectează mai multe)
    const selectedImages = imagesArray.slice(0, 25);
  
    const compressedImages = [];
  
    // Comprimăm fiecare imagine și adăugăm imaginea comprimată la listă
    for (const image of selectedImages) {
      try {
        const compressedImage = await compressImage(image, 0.8); // Comprimăm imaginea cu o calitate de 0.8 (80%)
        compressedImages.push(compressedImage);
      } catch (error) {
        console.error("Eroare la comprimarea imaginilor:", error);
      }
    }
  
    // Setăm imaginile comprimate în starea componentei
    setSelectedImages(compressedImages);
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // modal Editare
  const modalRef = useRef(null);
  useEffect(() => {
    // Inițializarea modalului Bootstrap
    modalRef.current = new Modal(document.getElementById('editModal'));
  }, []);
  

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postCollectionRef = collection(firestore, 'post');
        const querySnapshot = await getDocs(postCollectionRef);
        const dataArray = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          dataArray.push({ id: doc.id, ...docData }); // Adaugă ID-ul documentului în obiectul de date
        });

        setValue(dataArray);
      } catch (error) {
      }
    };
    fetchData();
  }, [firestore]);

  

  // Functie Editare
  const handleEdit = (values) => {
    setCurrentDocId(values.id);
    setCurrentDocId(values.id);
    setMarca(values.marca);
    setModel(values.model);
    setCombustibil(values.combustibil);
    setMotor(values.motor);
    setPutere(values.putere);
    setCutieViteza(values.cutieViteza);
    setNormaPoluare(values.normaPoluare);
    setCuloare(values.culoare);
    setAnFabricatie(values.anFabricatie)
    setRulaj(values.rulaj)
    setCaroserie(values.caroserie)
    setTractiune(values.tractiune)
    setPret(values.pret)
    setSasiu(values.sasiu)
    setDiscount(values.pretNou)
 setPretCuTVA(values.pretCuTVA)
 setDescriere(values.descriere)
 setGarantie(values.garantie)
 setTara(values.tara)
 setInmatriculare(values.inmatriculare)
 setInmatriculat(values.inmatriculat)
 setPropietar(values.propietar)
 setService(values.service)
 setAccident(values.accident)
 setComanda(values.comanda)
 setLocuri(values.locuri)
   setTitlu(values.titlu)

    // Inițializează starea cu opțiunile de confort existente din Firestore
    setSelectedComfortOptions(getSelectedComfortOptions(values.comfortOptions));

    setSelectedAudioOptions(getSelectedAudioOptions(values.audioOptions))
    setSelectedSigurantaOptions(getSelectedSigurantaOptions(values.sigurantaOptions))
    setSelectedAsistentaOptions(getSelectedAsistentaOptions(values.asistentaOptions))

    setMain(values.mainImage)
    setImages(values.images)
    setShow(true);
    modalRef.current.show();
  };
  
  
  //Update
  const handleUpdate = async () => {
    try {
      setShowModal(true)
      if (currentDocId) {
        const updateData = doc(firestore, 'post', currentDocId);

        if (selectedMainImg.length === 1) {
          let currentMainImageName = null;

          // Obține numele imaginii principale curente
          const postDocData = await getDoc(doc(firestore, 'post', currentDocId));
          const postDoc = postDocData.data();
          if (postDoc && postDoc.mainImage) {
            currentMainImageName = Object.keys(postDoc.mainImage)[0];
          }

          // Șterge vechea imagine principală (dacă există)
          if (currentMainImageName) {
            const oldMainImageRef = ref(storage, "mainImages/" + currentMainImageName);
            await deleteObject(oldMainImageRef);
          }

          // Încarcă și actualizează noua imagine principală
          const mainImage = selectedMainImg[0];
          const storageRef = ref(storage, "mainImages/" + mainImage.name);
          const snapshot = await uploadBytes(storageRef, mainImage);
          const downloadURL = await getDownloadURL(snapshot.ref);
          const postDocRef = doc(firestore, "post", currentDocId);
          await updateDoc(postDocRef, {
            mainImage: { [mainImage.name]: downloadURL },
          });
        }
       
       
       
        if (selectedImages.length > 0) {
          const imagesObject = {};
        
          // Obține imaginile actuale din Firestore
          const postDocData = await getDoc(doc(firestore, 'post', currentDocId));
          const postDoc = postDocData.data();
        
          if (postDoc && postDoc.images) {
            // Șterge imaginile vechi
            for (const imageName of Object.keys(postDoc.images)) {
              const oldImageRef = ref(storage, "images/" + imageName);
              await deleteObject(oldImageRef);
            }
          }
        
          // Încarcă și actualizează noile imagini
          for (const image of selectedImages) {
            const storageRef = ref(storage, "images/" + image.name);
            const snapshot = await uploadBytes(storageRef, image);
            const downloadURL = await getDownloadURL(snapshot.ref);
            imagesObject[image.name] = downloadURL;
          }
        
          const postDocRef = doc(firestore, "post", currentDocId);
          await updateDoc(postDocRef, {
            images: imagesObject,
          });
        }
        
     
     
        //  update comfort optiuni
        const selectedComfortOptionsObject = {};
        for (const option in selectedComfortOptions) {
          selectedComfortOptionsObject[option] = selectedComfortOptions[option];
        }
        // update audio
        const selectedAudiOptionsObject = {};
        for (const option in selectedAudioOptions) {
          selectedAudiOptionsObject[option] = selectedAudioOptions[option];
        }
        // update siguranta
        const selectedSigurantaOptionsObject = {};
        for (const option in selectedSigurantaOptions) {
          selectedSigurantaOptionsObject[option] = selectedSigurantaOptions[option];
        }
         // update asistenta
         const selectedAsistentaOptionsObject = {};
         for (const option in selectedAsistentaOptions) {
          selectedAsistentaOptionsObject[option] = selectedAsistentaOptions[option];
         }

        
  
        await updateDoc(updateData, {
          marca: marca,
          combustibil: combustibil,
          putere: putere,
          cutieViteza: cutieViteza,
          normaPoluare: normaPoluare,
          rulaj: rulaj,
          culoare: culoare,
          anFabricatie: anFabricatie,
          motor: motor,
          model: model,
          caroserie:caroserie,
          tractiune:tractiune,
          pret:pret,
          sasiu: sasiu,
          pretCuTVA: pretCuTVA,
          comfortOptions: selectedComfortOptionsObject,
          audioOptions: selectedAudiOptionsObject,
          sigurantaOptions: selectedSigurantaOptionsObject,
          asistentaOptions: selectedAsistentaOptionsObject,
          pretNou:discount,
          descriere:descriere,
          garantie:garantie,
          tara:tara,
          inmatriculare:inmatriculare,
          inmatriculat:inmatriculat,
          propietar:propietar,
          service:service,
          accident:accident,
          locuri:locuri,
          comanda:comanda,
          titlu:titlu
        });

      // Actualizarea datelor local pentru a reflecta modificările
      const updatedDataArray = [...value];
      const updatedIndex = updatedDataArray.findIndex((item) => item.id === currentDocId);
      if (updatedIndex !== -1) {
        updatedDataArray[updatedIndex] = {
        id: currentDocId,
        marca: marca,
        combustibil: combustibil,
        putere: putere,
        cutieViteza: cutieViteza,
        normaPoluare: normaPoluare,
        rulaj: rulaj,
        culoare: culoare,
        anFabricatie: anFabricatie,
        motor: motor,
        model: model,
        caroserie:caroserie,
        tractiune:tractiune,
        pret:pret,
        sasiu: sasiu,
        descriere:descriere,
        garantie:garantie,
        tara:tara,
        inmatriculare:inmatriculare,
        inmatriculat:inmatriculat,
        propietar:propietar,
        service:service,
        accident:accident,
        locuri:locuri,
        comanda:comanda,
        titlu:titlu,
          comfortOptions: selectedComfortOptionsObject,
          audioOptions: selectedAudiOptionsObject,
          sigurantaOptions: selectedSigurantaOptionsObject,
          asistentaOptions: selectedAsistentaOptionsObject
         
        };

        setValue(updatedDataArray);
      }
        // Dacă actualizarea a fost reușită, închide modalul și resetează stările
        setShow(false);
        setCurrentDocId(null);
        setMarca("");
        setModel("")
        setTractiune("")
        setCaroserie("")
        setCombustibil("");
        setPutere("");
        setCutieViteza("");
        setNormaPoluare("");
        setRulaj("");
        setAnFabricatie("");
        setCuloare("");
        setMotor("");
        setPret("")
        setSasiu("")
        setPretCuTVA("")
        setDescriere("")
        setDiscount("")
        setGarantie("")
        setTara("")
        setInmatriculare("")
        setInmatriculat("")
        setPropietar("")
        setAccident("")
        setService("")
        setComanda("")
        setLocuri("")
        setTitlu("")
        setSelectedComfortOptions({})
        setSelectedAudioOptions({})
        setSelectedSigurantaOptions({})
        setSelectedAsistentaOptions({})
        setSelectedMainImg([]);
        setSelectedImages([]);
        alert("Autoturismul s-a actualizat cu succes!")
        modalRef.current.hide();
        window.location.reload();
        // Nu este nevoie să reîncărcați întreaga pagină pentru a afișa actualizările
        // Dacă valoarea se actualizează corespunzător în Firestore, aceasta va fi reflectată automat în interfața ta.
      }
    } catch (error) {
      // Tratează orice eroare care ar putea apărea în timpul actualizării
      console.error('Eroare la actualizare:', error);
    }
    setShowModal(false)
  };




 
  const handleDelete = async (id) => {
    try {
      setShowModal(true)
      if (id) {
        const deleteVal = doc(firestore, 'post', id);
        const documentSnapshot = await getDoc(deleteVal);
        const data = documentSnapshot.data();
    
        if (data) {
          // Șterge imaginile din Storage dacă există
          if (data.images) {
            for (const imageName in data.images) {
              // Construiți calea pentru imagine
              const imageRefPath = "images/" + imageName;
              const imageRef = ref(storage, imageRefPath);
              await deleteObject(imageRef);
            }
          }
    
         // Șterge mainImage din Storage
         if (data.mainImage) {
          for( const mainName in data.mainImage) {

            const mainImageRefPath = "mainImages/" + mainName;
            const mainImageRef = ref(storage, mainImageRefPath);
            await deleteObject(mainImageRef);
          }
        }
    
          // Șterge documentul din Firestore
          await deleteDoc(deleteVal);
    
         alert('Autoturismul a fost șters cu succes.');
      
          window.location.reload();
    
          // Poți actualiza local lista de date eliminând elementul cu id-ul specificat
          setValue((prevValue) => prevValue.filter((item) => item.id !== id));
        } else {
          console.log('Documentul nu a fost găsit.');
        }
      }
    } catch (error) {
      console.error('Eroare la ștergere:', error);
    }
    setShowModal(false)
  };


  
  // Funcția pentru ștergerea imaginilor
const deleteSelectedImage = async (imageName) => {
  try {
    // Verifică dacă imaginea există în Firestore și o șterge
    const postDocData = await getDoc(doc(firestore, 'post', currentDocId));
    const postDoc = postDocData.data();

    if (postDoc && postDoc.images && postDoc.images[imageName]) {
      // Șterge imaginea din Firestore
      delete postDoc.images[imageName];
      const postDocRef = doc(firestore, "post", currentDocId);
      await updateDoc(postDocRef, {
        images: postDoc.images,
      });

      // Șterge imaginea din stocare
      const imageRef = ref(storage, "images/" + imageName);
      await deleteObject(imageRef);

      console.log("Imaginea a fost ștearsă cu succes.");
     setImages(postDoc.images)
    } else {
      console.log("Imaginea nu a fost găsită.");
    }
  } catch (error) {
    console.error('Eroare la ștergere imagine:', error);
  }
};

const [selectednewImg, setNewImage] = useState([]);


const handleFileChange = (e) => {
  const file = e.target.files[0]; // Accesează primul fișier din lista de fișiere

  if (file) {
    setNewImage(file);
  }
};



const handleImageChange = async (key) => {
  try {
    if (currentDocId && selectednewImg) {
      // Șterge imaginea veche din Firestore și stocare
      const postDocData = await getDoc(doc(firestore, 'post', currentDocId));
      const postDoc = postDocData.data();

      if (postDoc && postDoc.images && postDoc.images[key]) {
        const oldImageName = key;
        const oldImageRef = ref(storage, "images/" + oldImageName);
        await deleteObject(oldImageRef);
      }

      // Încarcă și actualizează noua imagine
      const storageRef = ref(storage, "images/" + selectednewImg.name);
      const snapshot = await uploadBytes(storageRef, selectednewImg);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const imagesObject = {
        ...postDoc.images,
        [key]: downloadURL
      };

      const postDocRef = doc(firestore, "post", currentDocId);
      await updateDoc(postDocRef, {
        images: imagesObject,
      });

      console.log("Imaginea a fost schimbată cu succes.");
      alert("Imaginea a fost schimbată cu succes.")
      //aici trebuie actualizare
    }
  } catch (error) {
    console.error('Eroare la schimbarea imaginii:', error);
  }
};



// Apelul funcției handleImageChange atunci când dorești să schimbi imaginea
// Poți furniza noua imagine ca argument pentru funcție
// handleImageChange(newImage);

  
 


  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Numărul de articole pe pagină
  // Calculați indexul de start și sfârșit pentru rândurile curente
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Obțineți rândurile pentru pagina curentă
  const currentRows = value.slice(startIndex, endIndex);
  const totalPages = Math.ceil(value.length / itemsPerPage);
  // Funcție pentru a schimba pagina
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  


  return (
    <div className={`render-component ${theme === "dark" ? "dark-theme" : ""}`}>
      <h5 className='mx-3 my-3'>Listă mașini Website</h5>

    <Table currentRows={currentRows} showModal={showModal} setShowModal={setShowModal} handleEdit={handleEdit} handleDelete={handleDelete} theme={theme}/>

    {/* Paginarea */}
    <Pagination  currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} theme={theme} />

      {/* Modal */}
      <EditModal 
       marca={marca}
       setMarca={setMarca}
       model={model}
       setModel={setModel}
       combustibil={combustibil}
       setCombustibil={setCombustibil}
       motor={motor}
       setMotor={setMotor}
       putere={putere}
       setPutere={setPutere}
       cutieViteza={cutieViteza}
       setCutieViteza={setCutieViteza}
       normaPoluare={normaPoluare}
       setNormaPoluare={setNormaPoluare}
       culoare={culoare}
       setCuloare={setCuloare}
       anFabricatie={anFabricatie}
       setAnFabricatie={setAnFabricatie}
       rulaj={rulaj}
       setRulaj={setRulaj}
       caroserie={caroserie}
       setCaroserie={setCaroserie}
       tractiune={tractiune}
       setTractiune={setTractiune}
       pret={pret}
       setPret={setPret}
pretCuTVA={pretCuTVA}
setPretCuTVA={setPretCuTVA}
setDiscount={setDiscount}
discount={discount}
descriere={descriere}
setDescriere={setDescriere}
garantie={garantie}
setGarantie={setGarantie}
tara={tara}
setTara={setTara}
inmatriculare={inmatriculare}
setInmatriculare={setInmatriculare}
inmatriculat={inmatriculat}
setInmatriculat={setInmatriculat}
propietar={propietar}
setPropietar={setPropietar}
service={service}
setService={setService}
accident={accident}
setAccident={setAccident}
locuri={locuri}
setLocuri={setLocuri}
comanda={comanda}
setComanda={setComanda}
titlu={titlu}
setTitlu={setTitlu}




       sasiu={sasiu}
       setSasiu={setSasiu}
  setSelectedComfortOptions={setSelectedComfortOptions}
  selectedComfortOptions={selectedComfortOptions}
  setSelectedAsistentaOptions={setSelectedAsistentaOptions}
  selectedAsistentaOptions={selectedAsistentaOptions}
  setSelectedSigurantaOptions={setSelectedSigurantaOptions}
  selectedSigurantaOptions={selectedSigurantaOptions}
  setSelectedAudioOptions={setSelectedAudioOptions}
  selectedAudioOptions={selectedAudioOptions}
  handleMainImageChange={handleMainImageChange}
  main={main}
  handleImagesChange={handleImagesChange}
  images={images}
  handleUpdate={handleUpdate}
  theme={theme}
  deleteSelectedImage={deleteSelectedImage}
  handleImageChange={handleImageChange}
  handleFileChange={handleFileChange}
  selectednewImg ={selectednewImg }
  setNewImage={setNewImage}
  showModal={showModal} 
  setShowModal={setShowModal} 
/>

    </div>

  );
};

export default RenderDataComponent;
