import React, { useState, useEffect } from "react";
import { app, auth, firestore, storage } from "../../firebase";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import "./post.css";
import { comfortOptions, audioOptions, sigurantaOptions, asistentaOptions } from "./optiuni";

import { marciDeMasini } from "./listcars";
import { listaAnFabricatie } from "./anFabricatie";

async function compressImage(file, quality) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculăm noile dimensiuni ale imaginii pentru compresie
        const maxWidth = 800;
        const maxHeight = 600;
        let newWidth, newHeight;

        if (image.width > image.height) {
          newWidth = maxWidth;
          newHeight = (image.height / image.width) * maxWidth;
        } else {
          newHeight = maxHeight;
          newWidth = (image.width / image.height) * maxHeight;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        // Desenăm imaginea pe canvas cu noile dimensiuni
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        // Obținem imaginea comprimată sub formă de Blob
        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name, { type: 'image/jpeg' }));
        }, 'image/jpeg', quality);
      };
    };

    reader.readAsDataURL(file);
  });
}


const AddProject = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);


  const [marca, setMarca] = useState("");
  const [model, setModel] = useState("");
  const [combustibil, setCombustibil] = useState("");
  const [putere, setPutere] = useState("");
  const [cutieViteza, setCutieViteza] = useState("");
  const [normaPoluare, setNormaPoluare] = useState("");
  const [culoare, setCuloare] = useState("");
  const [anFabricatie, setAnFabricatie] = useState("");
  const [rulaj, setRulaj] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedMainImg, setSelectedMainImg] = useState([]);
  const [motor, setMotor] = useState("");
  const [caroserie, setCaroserie] = useState("");
  const [tractiune, setTractiune] = useState("");
  const [pretCuTVA, setPretCuTVA] = useState(false);

  const [pret, setPret] = useState("");
  const [discount, setDiscount] = useState("");

 const [descriere, setDescriere] = useState("");

 const [titlu, setTitlu] = useState("");
 
 const [garantie, setGarantie] = useState("");
 const [tara, setTara] = useState("");
 const [inmatriculare, setInmatriculare] = useState("");
 const [inmatriculat, setInmatriculat] = useState(false);
 const [propietar, setPropietar] = useState(false);
 const [service, setService] = useState(false);
 const [accident, setAccident] = useState(false);
 const [locuri, setLocuri] = useState("");

 const [comanda, setComanda] = useState(false);


  const [sasiu, setSasiu] = useState("")

  const handleTvaChange = (e) => {
    const isChecked = e.target.checked;
    setPretCuTVA(isChecked);

  };

  const handleInmatriculat = (e) => {
    const isChecked = e.target.checked;
    setInmatriculat(isChecked);

  };

  const handleComanda = (e) => {
    const isChecked = e.target.checked;
    setComanda(isChecked);

  };
  const handlePropietar = (e) => {
    const isChecked = e.target.checked;
    setPropietar(isChecked);

  };
  const handleService = (e) => {
    const isChecked = e.target.checked;
    setService(isChecked);

  };
  const handleAccident = (e) => {
    const isChecked = e.target.checked;
    setAccident(isChecked);

  };


  //  comfort
  const [selectedComfortOptions, setSelectedComfortOptions] = useState({});

  const handleComfortOptionChange = (comfort) => {
    setSelectedComfortOptions((prevOptions) => ({
      ...prevOptions,
      [comfort]: !prevOptions[comfort],
    }));
  };

  // audio
  const [selectedAudioOptions, setSelectedAudioOptions] = useState({});
  const handleAudioOptionChange = (audio) => {
    setSelectedAudioOptions((prevOptions) => ({
      ...prevOptions,
      [audio]: !prevOptions[audio],
    }));
  };

  // siguranta
  const [selectedSigurantaOptions, setSelectedSigurantaOptions] = useState({});
  const handleSigurantaOptionChange = (siguranta) => {
    setSelectedSigurantaOptions((prevOptions) => ({
      ...prevOptions,
      [siguranta]: !prevOptions[siguranta],
    }));
  };
  // asistenta
  const [selectedAsistentaOptions, setSelectedAsistentaOptions] = useState({});
  const handleAsistentaOptionChange = (asistenta) => {
    setSelectedAsistentaOptions((prevOptions) => ({
      ...prevOptions,
      [asistenta]: !prevOptions[asistenta],
    }));
  };

  const handlMarca = (e) => {
    setMarca(e.target.value.toUpperCase());
  };

  const handleModel = (e) => {
    setModel(e.target.value.toUpperCase());
  };


  const handleTitlu = (e) => {
    setTitlu(e.target.value.toUpperCase());
  };
  const handleCaroserie = (e) => {
    setCaroserie(e.target.value);
  };
  const handleTractiune = (e) => {
    setTractiune(e.target.value);
  };

  const handleCombustibil = (e) => {
    setCombustibil(e.target.value);
  };
  const handlePutere = (e) => {
    setPutere(e.target.value);
  };

  const handleCutie = (e) => {
    setCutieViteza(e.target.value);
  };
  const handleNorma = (e) => {
    setNormaPoluare(e.target.value);
  };

  const handleMotor = (e) => {
    setMotor(e.target.value);
  };

  const handleMainImgChange = async (e) => {
    const image = e.target.files[0];
    
    if (image) {
      try {
        const compressedImage = await compressImage(image, 0.8); // Comprimăm imaginea
        setSelectedMainImg([compressedImage]); // Setăm imaginea comprimată în starea componentei
      } catch (error) {
        console.error("Eroare la comprimarea imaginii:", error);
      }
    }
  };
  

// Funcția pentru manipularea imaginilor secundare atunci când utilizatorul selectează imagini
const handleImagesChange = async (e) => {
  const images = e.target.files;
  const imagesArray = Array.from(images);

  // Limităm numărul de imagini la 25 (dacă utilizatorul selectează mai multe)
  const selectedImages = imagesArray.slice(0, 25);

  const compressedImages = [];

  // Comprimăm fiecare imagine și adăugăm imaginea comprimată la listă
  for (const image of selectedImages) {
    try {
      const compressedImage = await compressImage(image, 0.8); // Comprimăm imaginea cu o calitate de 0.8 (80%)
      compressedImages.push(compressedImage);
    } catch (error) {
      console.error("Eroare la comprimarea imaginilor:", error);
    }
  }

  // Setăm imaginile comprimate în starea componentei
  setSelectedImages(compressedImages);
};


  const handleRulaj = (e) => {
    setRulaj(e.target.value);
  };

  const handleCuloare = (e) => {
    setCuloare(e.target.value);
  };
  const handleGarantie = (e) => {
   setGarantie(e.target.value);
  };
  const handleTara = (e) => {
    setTara(e.target.value.toUpperCase());
   };
 

   const handleInmatriculare = (e) => {
    setInmatriculare(e.target.value);
   };
 

  const handlePret = (e) => {
    setPret(e.target.value.toUpperCase());
  };
  const handlePretNou = (e) => {
    setDiscount(e.target.value);
  };
  const handleLocuri = (e) => {
    setLocuri(e.target.value);
  };

  const handleFabricatie = (e) => {
    setAnFabricatie(e.target.value);
  };

  const handleSasiu = (e) => {
    setSasiu(e.target.value.toUpperCase());
  };
  const handleDescriere = (e) => {
    let inputValue = e.target.value;
    let newValue = '';
  
    for (let i = 0; i < inputValue.length; i++) {
      if (i === 0 || (inputValue[i - 1] === '.' || inputValue[i - 1] === ';' || inputValue[i - 1] === '\n')) {
        // Capitalizează primul caracter din fiecare nou rând sau după un punct sau semn de punctuație
        newValue += inputValue[i].toUpperCase();
      } else {
        newValue += inputValue[i];
      }
    }
  
    setDescriere(newValue);

  };

  const resetFileInputs = () => {
    const mainImageInput = document.getElementById("mainImage");
    const imagesInput = document.getElementById("images");

    if (mainImageInput) {
      mainImageInput.value = ""; // Resetează câmpul de fișier pentru imaginea principală
    }

    if (imagesInput) {
      imagesInput.value = ""; // Resetează câmpul de fișier pentru imagini secundare
    }
  };

  const maxProgress = 100;

  const updateProgress = () => {
    const increment = maxProgress / 1;
    let currentProgress = 0;

    const progressInterval = setInterval(() => {
      currentProgress += increment;
      setUploadProgress(currentProgress);

      if (currentProgress >= maxProgress) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setShowProgressModal(false);
          setUploadProgress(0);
        }, 1000);
      }
    }, 1000);
  };

  const handleSubmit = async () => {
    if (
      !user ||
      !marca ||
      !combustibil ||
      !putere ||
      !cutieViteza ||
      !normaPoluare ||
      !rulaj ||
      !culoare ||
      !anFabricatie ||
      !motor ||
      !model ||
      !caroserie ||
      !tractiune ||
      !pret ||
      !descriere ||
      !tara ||
      !locuri ||
      !titlu

    ) {
      alert(
        "Completează toate câmpurile"
      );
      return;
    }

    try {
      setShowProgressModal(true);
      const postCollectionRef = collection(firestore, "post");

      const newDocRef = await addDoc(postCollectionRef, {
        userId: user.uid,
        marca: marca,
        combustibil: combustibil,
        putere: putere,
        cutieViteza: cutieViteza,
        normaPoluare: normaPoluare,
        rulaj: rulaj,
        culoare: culoare,
        anFabricatie: anFabricatie,
        motor: motor,
        model: model,
        caroserie: caroserie,
        tractiune: tractiune,
        pret: pret,
        sasiu: sasiu,
        comfortOptions: selectedComfortOptions,
        audioOptions: selectedAudioOptions,
        sigurantaOptions: selectedSigurantaOptions,
        asistentaOptions: selectedAsistentaOptions,
        pretCuTVA: pretCuTVA,
        pretNou: discount,
        descriere:descriere,
        tara: tara,
        garantie: garantie,
        inmatriculare: inmatriculare,
        inmatriculat:inmatriculat,
        propietar:propietar,
        service:service,
        accident:accident,
        locuri:locuri,
        comanda:comanda,
        titlu:titlu,
        createdAt: serverTimestamp()
        // Adăugăm ID-ul utilizatorului la document pentru a ține evidența autorului postului
      });

      if (selectedMainImg.length === 1) {
        const mainImage = selectedMainImg[0];
        const storageRef = ref(storage, "mainImages/" + mainImage.name);
        const snapshot = await uploadBytes(storageRef, mainImage);
        const downloadURL = await getDownloadURL(snapshot.ref);
        const postDocRef = doc(firestore, "post", newDocRef.id);
        await updateDoc(postDocRef, {
          mainImage: { [mainImage.name]: downloadURL },
        });
      }
      if (selectedImages.length > 0) {
        const imagesObject = {};

        for (const image of selectedImages) {
          const storageRef = ref(storage, "images/" + image.name);
          const snapshot = await uploadBytes(storageRef, image);
          const downloadURL = await getDownloadURL(snapshot.ref);
          imagesObject[image.name] = downloadURL;
        }
        const postDocRef = doc(firestore, "post", newDocRef.id);
        await updateDoc(postDocRef, {
          images: imagesObject,
        });
      }


      setMarca("");
      setModel("")
      setTractiune("")
      setCaroserie("")
      setCombustibil("");
      setPutere("");
      setCutieViteza("");
      setNormaPoluare("");
      setRulaj("");
      setAnFabricatie("");
      setCuloare("");
      setMotor("");
      setPret("")
      setSasiu("")
      setDiscount("")
      setDescriere("")
      setGarantie("")
      setTara("")
      setInmatriculare("")
      setInmatriculat("")
      setPropietar("")
      setAccident("")
      setService("")
      setComanda("")
      setLocuri("")
      setTitlu("")
      setSelectedAsistentaOptions({})
      setSelectedAudioOptions({})
      setSelectedComfortOptions({})
      setSelectedSigurantaOptions({})
      setSelectedMainImg([]);
      setSelectedImages([]);
      setPretCuTVA(false)
      resetFileInputs();
      updateProgress();
      alert("Autoturism incarcat cu succes")
    } catch (error) {
      alert("Eroare la adăugarea datelor în Firestore:", error);
      setShowProgressModal(false);
    }
  };

  return (
    <div className="px-1 py-5">
      <div>
        <div className="row px-3 w-100">
          <div className="col-md-12 col-lg-12">
            <h4 className="mb-3">Adăugare autoturism</h4>
          {/* Comanda checkbox */}
          <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-2 mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="comanda"
                      onChange={handleComanda}
                    />
                  <label className="form-check-label" htmlFor="comanda">
                  Pe comandă
                  </label>
                  </div>
                 
                </div>
                <div className="col-sm-12 mb-3">
                  <label htmlFor="titlu" className="form-label">
                    Scurta descriere *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="titlu"
                    value={titlu}
                    
                    placeholder="7 locuri, Navigatie, etc"
                    onChange={handleTitlu}
                    required
                  />
                </div>
            <div>

              
              <div className="row g-3">

                <div className="col-sm-2">
                  <label htmlFor="marca" className="form-label">
                    Marcă *
                  </label>
                  <select
                    value={marca}
                    className="form-select"
                    id="marca"
                    onChange={handlMarca}
                    required
                  >
                    <option value="">Alege...</option>
                    {marciDeMasini.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="model" className="form-label">
                    Model *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="model"
                    value={model}
                    onChange={handleModel}
                    required
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="combustibil" className="form-label">
                    Combustibil *
                  </label>
                  <select
                    value={combustibil}
                    className="form-select"
                    id="combustibil"
                    onChange={handleCombustibil}
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Benzină">Benzină</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Hibrid">Hibrid</option>
                    <option value="Electric">Electric</option>
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="motor" className="form-label">
                    Motor cm3 *
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="motor"
                    value={motor}
                    onChange={handleMotor}
                    required
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="putere" className="form-label">
                    Putere CP *
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="putere"
                    value={putere}
                    onChange={handlePutere}
                    required
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="cutieViteza" className="form-label">
                    Cutie Viteză *
                  </label>
                  <select
                    value={cutieViteza}
                    className="form-select"
                    id="cutieViteza"
                    onChange={handleCutie}
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Manuală">Manuală</option>
                    <option value="Automată">Automată</option>
                    <option value="Semiautomată">Semiautomată</option>
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="normaPoluare" className="form-label">
                    Normă Poulare *
                  </label>
                  <select
                    value={normaPoluare}
                    onChange={handleNorma}
                    className="form-select"
                    id="normaPoluare"
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Euro 3">Euro 3</option>
                    <option value="Euro 4">Euro 4</option>
                    <option value="Euro 5">Euro 5</option>
                    <option value="Euro 6">Euro 6</option>

                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="culoare" className="form-label">
                    Culoare *
                  </label>
                  <select
                    value={culoare}
                    onChange={handleCuloare}
                    className="form-select"
                    id="culoare"
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Alb">Alb</option>
                    <option value="Negru">Negru</option>
                    <option value="Gri">Gri</option>
                    <option value="Argintiu">Argintiu</option>
                    <option value="Albastru">Albastru</option>
                    <option value="Rosu">Rosu</option>
                    <option value="Verde">Verde</option>
                    <option value="Galben/Auriu">Galben/Auriu</option>
                    <option value="Maro/Bej">Maro/Bej</option>
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="anfab" className="form-label">
                    An Fabricație *
                  </label>
                  <select
                    value={anFabricatie}
                    className="form-select"
                    id="anfab"
                    onChange={(e) => {
                      // Parsează valoarea selectată ca număr
                      const selectedValue = parseInt(e.target.value, 10);
                      // Actualizează starea anFabricatie cu valoarea numerică
                      setAnFabricatie(selectedValue);
                    }}
                    required
                  >
                    <option value="">Alege...</option>
                    {listaAnFabricatie.map((an, index) => (
                      <option value={an} key={index}>{an}</option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="rulaj" className="form-label">
                    Rulaj KM *
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="rulaj"
                    value={rulaj}
                    onChange={handleRulaj}
                    required
                  ></input>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="caroserie" className="form-label">
                    Caroserie *
                  </label>
                  <select
                    value={caroserie}
                    onChange={handleCaroserie}
                    className="form-select"
                    id="caroserie"
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Cabrio">Cabrio</option>
                    <option value="Berlină">Berlină</option>
                    <option value="Break">Break</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="SUV">SUV</option>
                    <option value="Minibus">Minibus</option>
                    <option value="Monovolum">Monovolum</option>
                    <option value="Coupe">Coupe</option>
                    <option value="Pickup">Pickup</option>
                  </select>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="tractiune" className="form-label">
                    Tracțiune *
                  </label>
                  <select
                    value={tractiune}
                    onChange={handleTractiune}
                    className="form-select"
                    id="tractiune"
                    required
                  >
                    <option value="">Alege...</option>
                    <option value="Fata">Față</option>
                    <option value="Spate">Spate</option>
                    <option value="4X4 Automat">4X4 Automat</option>
                    <option value="4X4 Manual">4X4 Manual</option>

                  </select>
                </div>
                <div className="col-sm-12">
                  <label htmlFor="descriere" >
                  Descriere *
                  </label>
                  <textarea
                    type="descriere"
                    className="form-control"
                    id="descriere"
                    value={descriere}
                    rows={3}
                    onChange={handleDescriere}
                    style={{ resize: "none" }}
                    required
                  />
                </div>

                {/* garantie */}
                <div className="col-sm-2">
                  <label htmlFor="garantie" className="form-label">
                   Garantie
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="garantie"
                    value={garantie}
                    onChange={handleGarantie}
                  
                  />
                </div>
                {/* Trara de origine */}
                <div className="col-sm-2">
                  <label htmlFor="tara" className="form-label">
                   Tara de Origine *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="tara"
                    value={tara}
                    onChange={handleTara}
                    required
                  />
                </div>
                 {/* data inmatriculare */}
                 <div className="col-sm-2">
                  <label htmlFor="inmatriculare" className="form-label">
                   Data primei inmatriculari
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inmatriculare"
                    placeholder="DD-LL-AAAA"
                    value={inmatriculare}
                    onChange={handleInmatriculare}
                   
                  />
                </div>
                 {/* Locuri */}
                 <div className="col-sm-2">
                  <label htmlFor="locuri" className="form-label">
                  Numar locuri *
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="locuri"
                    value={locuri}
                    onChange={handleLocuri}
                    required
                  />
                </div>


                <div className="row gy-3">
                 {/* Inmatriculat  */}
                 <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inmatriculat "
                      onChange={handleInmatriculat}
                    />
                  <label className="form-check-label" htmlFor="inmatriculat">
                  Inmatriculat 
                  </label>
                  </div>
                </div>
                {/* propietar */}
                <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="propietar"
                      onChange={handlePropietar}
                    />
                  <label className="form-check-label" htmlFor="propietar">
                  Primul propietar
                  </label>
                  </div>
                </div>
                 {/* Fara accident in istoric */}
                 <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="accident"
                      onChange={handleAccident}
                    />
                  <label className="form-check-label" htmlFor="accident">
                  Fara accident in istoric
                  </label>
                  </div>
                </div>
                 {/* Carte service */}
                 <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="service"
                      onChange={handleService}
                    />
                  <label className="form-check-label" htmlFor="service">
                  Carte service
                  </label>
                  </div>
                </div>

                <hr className="my-4" />
              </div>
              </div>

              {/* Comfort */}
              <div className="row gy-3">
              <div className="col-sm-12 h5" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">

              Comfort si Optionale <i className="fa-solid fa-caret-down"  ></i>
              </div>
                <div className="col-sm-12 collapse" id="collapseExample"> 

                  
               
                  {comfortOptions.map((comfort) => (
                    <div key={comfort} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={comfort}
                        checked={selectedComfortOptions[comfort] || false}
                        onChange={() => handleComfortOptionChange(comfort)}
                      />
                      <label className="form-check-label" htmlFor={comfort}>
                        {comfort}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>

              {/* Audio */}
              <div className="row gy-3">
              <div className="col-sm-12 h5"  type="button" data-toggle="collapse" data-target="#audioCollapse" aria-expanded="false" aria-controls="audioCollapse" >

Audio si Tehnologie  <i className="fa-solid fa-caret-down" ></i>
</div>
  <div className="col-sm-12 collapse" id="audioCollapse"> 
                
                  {audioOptions.map((audio) => (
                    <div key={audio} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={audio}
                        checked={selectedAudioOptions[audio] || false}
                        onChange={() => handleAudioOptionChange(audio)}
                      />
                      <label className="form-check-label" htmlFor={audio}>
                        {audio}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>
              {/* Siguranta */}
              <div className="row gy-3">
              <div className="col-sm-12 h5" type="button" data-toggle="collapse" data-target="#sigurantaCollapse" aria-expanded="false" aria-controls="sigurantaCollapse">

Siguranta si Performanta <i className="fa-solid fa-caret-down"  ></i>
</div>
  <div className="col-sm-12 collapse" id="sigurantaCollapse"> 
                  {sigurantaOptions.map((siguranta) => (
                    <div key={siguranta} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={siguranta}
                        checked={selectedSigurantaOptions[siguranta] || false}
                        onChange={() => handleSigurantaOptionChange(siguranta)}
                      />
                      <label className="form-check-label" htmlFor={siguranta}>
                        {siguranta}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>
              {/* Asistenta */}
              <div className="row gy-3">
              <div className="col-sm-12 h5"  type="button" data-toggle="collapse" data-target="#asistentaCollapse" aria-expanded="false" aria-controls="asistentaCollapse">

Asistenta si Electronice <i className="fa-solid fa-caret-down" ></i>
</div>
  <div className="col-sm-12 collapse" id="asistentaCollapse"> 
                  {asistentaOptions.map((asistenta) => (
                    <div key={asistenta} className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={asistenta}
                        checked={selectedAsistentaOptions[asistenta] || false}
                        onChange={() => handleAsistentaOptionChange(asistenta)}
                      />
                      <label className="form-check-label" htmlFor={asistenta}>
                        {asistenta}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="my-4" />
              </div>
              {/* Poze */}
              <div className="row gy-3">
                <div className="col-sm-3">
                  <label htmlFor="mainImage" className="form-label">
                    Imagine principală *
                  </label>
                  <input
                    type="file"
                    className="form-control mb-2"
                    id="mainImage"
                    required
                    onChange={handleMainImgChange}
                  />
                  {selectedMainImg.length === 1 && (
                    <div className="text-center ">
                      <img
                        src={URL.createObjectURL(selectedMainImg[0])}
                        alt="ProjectIMG"
                        loading="lazy"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="images" className="form-label">
                    Imagini - maxim 25 *
                  </label>
                  <input
                    type="file"
                    className="form-control mb-2"
                    id="images"
                    multiple
                    required
                    onChange={handleImagesChange}
                  />
                  {selectedImages.length > 0 && (
                    <div className="text-center ">
                      {selectedImages.slice(0, 25).map((image, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt="ProjectIMGs"
                          loading="lazy"
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>

              </div>
              <hr className="my-4" />

              {/* Pret */}
              <div className="row gy-3 ">
                <div className="col-sm-2">
                  <label htmlFor="pret">Preț *</label>
                  <input
                    type="number"
                    className="form-control"
                    id="pret"
                    value={pret}
                    onChange={handlePret}
                    required
                  ></input>
                </div>


                <div className="col-sm-2">
                  <div className="form-check form-check-inline mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="tva"
                      onChange={handleTvaChange}
                    />
                  <label className="form-check-label" htmlFor="tva">
                  Preț cu TVA
                  </label>
                  </div>
                </div>
              <div className="col-sm-3">
                  <label htmlFor="sasiu" >
                    Serie șasiu
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sasiu"
                    value={sasiu}
                    onChange={handleSasiu}
                    
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="discount">Preț Nou</label>
                  <input
                    type="number"
                    className="form-control"
                    id="discount"
                    value={discount}
                    onChange={handlePretNou}
                   
                  ></input>
                </div>
                <hr className="my-4" />
              </div>

             
             

              <button
                className="w-100 btn btn-primary"
                onClick={handleSubmit}
              >
                Adaugare masina
              </button>
            </div>
          </div>
        </div>
      </div>
      {showProgressModal && (
        <div className="progress-modals">
          <div className="modal-contents">
            {uploadProgress < 100 ? (
              <>
                <p>Încărcare în progres...</p>
                <div className="progress-bar-containers">
                  <div
                    className="progress-bars"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              </>
            ) : (
              <div>
                <p className="success-messages">
                  Autoturismul s-a încărcat cu succes!
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default AddProject;
