import React ,{useState} from 'react'

const Button = ({ theme, setTheme }) => {

   
      const toggleTheme = () => {
        // Aici schimbăm tema între light și dark
        setTheme(theme === "light" ? "dark" : "light");
      };
  return (
    <div className="btn-group">
    <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
         
        >
          <i className="fa-solid fa-circle-half-stroke"></i>
        </button>
        <ul className="dropdown-menu">
          <li>
            <button className="dropdown-item" onClick={toggleTheme}>
              Schimbă culoarea
            </button>
          </li>
         
        </ul>
    </div>
  )
}

export default Button;