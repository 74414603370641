import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage , ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD6sFxWclayaP0eUblAOSu8K1uUo17YK74",
  authDomain: "parcautotimisoara-v1.firebaseapp.com",
  projectId: "parcautotimisoara-v1",
  storageBucket: "parcautotimisoara-v1.appspot.com",
  messagingSenderId: "961049894063",
  appId: "1:961049894063:web:13bd72c7738e9156c1085a"
};

  export const app = initializeApp(firebaseConfig);
  export const firestore = getFirestore(app);
  export const auth = getAuth(app);
  export   const storage = getStorage(app);