import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useNavigate } from 'react-router-dom';
import "./signin.css"
import logo from "../logo/logofooter.png"


const SingIn = () => {
  const navigate = useNavigate();
  const firebaseConfig = {
    apiKey: "AIzaSyD6sFxWclayaP0eUblAOSu8K1uUo17YK74",
    authDomain: "parcautotimisoara-v1.firebaseapp.com",
    projectId: "parcautotimisoara-v1",
    storageBucket: "parcautotimisoara-v1.appspot.com",
    messagingSenderId: "961049894063",
    appId: "1:961049894063:web:13bd72c7738e9156c1085a"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(); // Inițializare autentificare Firebase

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
 const [showModal  ,setShowModal] = useState(false)  


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const login = async () => {
    try {
      setShowModal(true)
      const userCredential = await signInWithEmailAndPassword(auth, email, password); // Modificare utilizare
      const user = userCredential.user;

      if (user) {  
        // Așteptați 3 secunde și apoi navigați
        setTimeout(() => {
          setShowModal(false);
          navigate('/dashbord');
        }, 1500); 
       
      }
      
    } catch (error) {
      // Tratați cazurile în care autentificarea eșuează
      const errorCode = error.code;
      const errorMessage = error.message;
     
      alert(`Autentificare eșuată: ${errorMessage}`);
    }
    setShowModal(false)
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
    { showModal && (
      <div className='modalLoading'>
        <div className="spinner-border  text-light" >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
        )}
      <div className="text-center signin">
        <div className="form-signin text-light">
          <div>
            {/* <h1 className="h2 mb-5 fw-bold">MDB Auto </h1> */}
            <img src={logo} className='img-fluid mb-4'/>
            <div className="form-floating text-dark mb-2">
              <input type="email" className="form-control" placeholder='Email' id="user" value={email} onChange={handleEmailChange} />
              <label htmlFor="user">Email</label>
            </div>
            <div className="form-floating text-dark">
              <input type={showPassword ? "text" : "password"} className="form-control" placeholder='Parola' id="pass" value={password} onChange={handlePasswordChange} />
              <label htmlFor="pass">Parola</label>
              <i className="ri-eye-off-line login__eye" id="input-icon" onClick={togglePasswordVisibility}></i>
            </div>
            <div className=" justify-content-center   mt-3 mb-3">
              <i className={`fas  ${showPassword ? "fa-eye-slash" : "fa-eye"}`} id="input-icon" onClick={togglePasswordVisibility}></i>
              <p className='d-inline px-3  ' style={{ userSelect: "none" }} >Vezi parola</p>
            </div>
            <input type="submit" className="w-100 btn btn-lg btn-primary mt-2" id="login" value="Logare" onClick={login} />
            <div className=' mt-5'>
              <p className="mt-5 mb-3  d-inline" style={{ color: "gray", textDecorationLine: "none" }}>&copy; 2023  <a className='d-inline' style={{ color: "gray", textDecorationLine: "none" }} href="https://smartweblab.ro">Smart Web Lab</a></p>
            </div>

          </div>
        </div>

      </div>


    </>

  )
}

export default SingIn
